<template>
    <nav ref="sidebar" class="h-screen w-64 bg-teal-900 text-white flex flex-col p-4">
        <!-- Navigation Links -->
        <ul v-if="auth.user.type === 'operator'" class="space-y-2 mt-2">
            <!-- Registration Menu Item -->
            <li>
                <button @click="toggleSubMenu" class="w-full text-white p-2 rounded flex items-center space-x-2">
                    Upravljanje korisnicima
                </button>
                <!-- Sub Menu -->
                <ul v-show="subMenuOpen" class="pl-4 space-y-2">
                    <li>
                        <Link @click="handleSubMenuClick" href="/users/operator" class="w-full text-white p-2 rounded flex items-center space-x-2">
                            Operatori
                        </Link>
                    </li>
                    <li>
                        <Link @click="handleSubMenuClick" href="/users/employer" class="w-full text-white p-2 rounded flex items-center space-x-2">
                            Poslodavci
                        </Link>
                    </li>

                    <li>
                        <Link @click="handleSubMenuClick" href="/users/worker" class="w-full text-white p-2 rounded flex items-center space-x-2">
                            Radnici
                        </Link>
                    </li>
                </ul>
            </li>
            <!-- Other Menu Items -->
            <li><Link @click="handleMainMenuClick" href="/jobTypes" class="p-2 rounded">Tipovi poslova</Link></li>
            <li><Link @click="handleMainMenuClick" href="/facilityTypes" class="p-2 rounded">Tipovi objekata</Link></li>
            <li><Link @click="handleMainMenuClick" href="/languages" class="p-2 rounded">Jezici</Link></li>
            <li><Link @click="handleMainMenuClick" href="/geography" class="p-2 rounded">Geo</Link></li>         
        </ul>

        <ul v-else-if="auth.user.type === 'employer'" class="space-y-2 mt-2">
            <li><Link href="/employers/profile" class="p-2 rounded">Profil poslodavca</Link></li>
            
            <template v-if="isEmployerProfileCreated">
                <li><Link href="/employers/facility" class="p-2 rounded">Lokali</Link></li>
                <li><Link href="/employers/advertisement" class="p-2 rounded">Oglasi</Link></li> 
            </template>    
        </ul>

        <ul v-else-if="auth.user.type === 'worker'" class="space-y-2 mt-2">
            <li>
                <Link @click="handleMainPhotoMenuClick" href="/workers/profile" class="p-2 rounded">Profil radnika</Link>
            </li>

            <template v-if="isWorkerProfileCreated">
                <li>
                    <Link @click="handleSubMenuClick" href="/workers/cv" class="w-full text-white p-2 rounded flex items-center space-x-2">
                        CV
                    </Link>
                </li>

                <li>
                    <Link @click="handleSubMenuClick" href="/workers/experience" class="w-full text-white p-2 rounded flex items-center space-x-2">
                        Radno Iskustvo
                    </Link>
                </li> 
                            
                <li>
                    <button @click="togglePhotoMenu" class="w-full text-white p-2 rounded flex items-center space-x-2">
                    Fotografije
                    </button>
                    <!-- Submeniji za Fotografije -->
                    <ul v-show="photoMenuOpen" class="pl-4 space-y-2">
                        <li>
                            <Link @click="handleSubMenuClick" href="/workers/avatar" class="w-full text-white p-2 rounded flex items-center space-x-2">
                            Avatar
                            </Link>
                        </li>
                        <li>
                            <Link @click="handleSubMenuClick" href="/workers/photos" class="w-full text-white p-2 rounded flex items-center space-x-2">
                            Dodatne fotografije
                            </Link>
                        </li>
                    </ul>
                </li>
            </template>
        </ul>
    </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { usePage } from '@inertiajs/vue3';
import axios from 'axios';

const { auth } = usePage().props;
const subMenuOpen = ref(false);
const sidebar = ref(null);

// Toggle sub-menu visibility
const toggleSubMenu = () => {
    subMenuOpen.value = !subMenuOpen.value;
};

// Close sub-menu when clicking on a sub-menu item
const handleSubMenuClick = () => {
    //subMenuOpen.value = false;
};

// Close sub-menu when clicking on any other main menu item
const handleMainMenuClick = () => {
    subMenuOpen.value = false;
};

const photoMenuOpen = ref(false);

const togglePhotoMenu = () => {
  photoMenuOpen.value = !photoMenuOpen.value;
};

const handleMainPhotoMenuClick = () => {
    photoMenuOpen.value = false;
};

/*
// Handle clicks outside the sidebar
const clickOutsideHandler = (event) => {
    if (sidebar.value && !sidebar.value.contains(event.target)) {
        subMenuOpen.value = false;
    }
};

// Set up and clean up event listeners
onMounted(() => {
    document.addEventListener('click', clickOutsideHandler);
});

onUnmounted(() => {
    document.removeEventListener('click', clickOutsideHandler);
});
*/

const isWorkerProfileCreated = ref(false);
const workerProfileManuallyCreated = ref(false);

const isEmployerProfileCreated = ref(false);
const employerProfileManuallyCreated = ref(false);

// Function that checks the status of the worker's profile from the backend
const fetchWorkerProfileStatus = async () => {
    try {
        const response = await axios.get('/api/worker-profile-status');
        isWorkerProfileCreated.value = response.data.isCreated;  // Received status from backend
    } catch (error) {
        console.error('Error fetching worker profile status:', error);
    }
};

// Function that checks if the user has a worker profile created
const checkWorkerProfile = () => {
    if (auth.user.worker || workerProfileManuallyCreated.value) {
        isWorkerProfileCreated.value = true; // If the profile exists, set to true
    } else {
        fetchWorkerProfileStatus();  // Call API to check status if not created
    }
};

// Function that checks the status of the employer's profile from the backend
const fetchEmployerProfileStatus = async () => {
    try {
        const response = await axios.get('/api/employer-profile-status');
        isEmployerProfileCreated.value = response.data.isCreated;  // Received status from backend
    } catch (error) {
        console.error('Error fetching employer profile status:', error);
    }
};

// Function that checks if the user has a employer profile created
const checkEmployerProfile = () => {
    if (auth.user.employer || employerProfileManuallyCreated.value) {
        isEmployerProfileCreated.value = true; // If the profile exists, set to true
    } else {
        fetchEmployerProfileStatus();  // Call API to check status if not created
    }
};

onMounted(() => {
    checkWorkerProfile();    
    window.addEventListener('worker-profile-created', () => {
        workerProfileManuallyCreated.value = true;
        checkWorkerProfile();
    });

    checkEmployerProfile();
    window.addEventListener('employer-profile-created', () => {
        employerProfileManuallyCreated.value = true;
        checkEmployerProfile();
    });
});

onUnmounted(() => {
    // Removing the event when the component is destroyed
    window.removeEventListener('worker-profile-created', checkWorkerProfile);
    window.removeEventListener('employer-profile-created', checkEmployerProfile);
});
</script>

<style scoped>
/* Ensure smooth transitions for the sub-menu */
.pl-4 {
    padding-left: 1rem;
}

a, button {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 8px;
}

a::before, button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    /*background-color: rgba(107, 114, 128, 0.5);*/
    z-index: -1;
    border-radius: 8px;
}

a:hover, button:hover {
    background-color: rgba(107, 114, 128, 0.5);
}

ul {
    list-style: none;
    width: 100%;
    margin-top: 0.5rem; /* Smaller top margin to bring the menu closer to the top */
    padding: 0;
}
</style>
