import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp, Head, Link } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';

import GuestLayout from './Layouts/GuestLayout.vue';
import UserLayout from './Layouts/UserLayout.vue';

const appName = import.meta.env.VITE_APP_NAME || 'Ugostitelj';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        const page = (await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))).default;

        page.layout = window.Laravel.isAuthenticated ? UserLayout : GuestLayout;

        //page.layout = DetermineLayout;

        return page;
    },

    setup({ el, App, props, plugin }) {

        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .component('Link', Link)
            .component('Head', Head)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
        showSppiner: false
    },
});